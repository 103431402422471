import { DynamicblockSettings } from "../dynamicblock-settings";
import { autoinject, bindable, Disposable } from 'aurelia-framework';
import { DynamicBlockTypes } from "components/admin/admin-constants/index";

import * as L from 'leaflet';
import { wktToGeoJSON } from "@terraformer/wkt";
import { EventAggregator } from "aurelia-event-aggregator";
import { Coord, DynamicBlocksService, IDynamicBlock, Nvdb, Polygon } from "components/admin/services/dynamic-block-service";
import { ApplicationRepository } from "services/application-repository/application-repository";
import { AdminDynamicBlockEventItemType } from "components/admin/admin-constants/event-types/admin-dynamic-block-event-item-type.enum";


@autoinject()
export class DynamicblockCard {
	@bindable dynamicblock: IDynamicBlock;
	@bindable color: string;
	@bindable coord: Coord;
	@bindable polygon: Polygon;
	@bindable nvdb: Nvdb;
	@bindable parent: DynamicblockSettings;

	dynamicBlockType = {
        NVDB: DynamicBlockTypes.NVDB,
		COORD: DynamicBlockTypes.Coord,
		Polygon: DynamicBlockTypes.Polygon
	}

	private mapCrs;
	private marker: L.Marker;
	private polygonInMap: L.Polygon;
	private radiusOnMarker: L.Circle;
	private linkSequencesInMap: L.Polyline[];
	private map: L.Map;
	protected showBlock = true;
	protected showLS = true;
	expandededit = false;
	private reloadDataSubscription: Disposable;

	constructor(
		private applicationRepo: ApplicationRepository,
		private eventAggregator: EventAggregator,
		private dynamicblockService: DynamicBlocksService,){}

	attached(){
		this.map = this.applicationRepo.map;
		this.mapCrs = this.applicationRepo.mapCrs;
		this.showBlockInMap();
		this.showLinqSequencesInMap(this.dynamicblock.Type);

		this.reloadDataSubscription = this.eventAggregator.subscribe(AdminDynamicBlockEventItemType.RELOAD_CARDS, () => {
			this.clearMap();
			this.showBlockInMap();
			this.showLinqSequencesInMap(this.dynamicblock.Type);
		});
	}

	detached() {
		if (this.map) {
			this.clearMap();
		}
		this.marker = null;
	}


	get blockColor(): string {
		return 'background-color: ' + this.color + '!important';
	}

	redraw(){
		this.clearMap();
		this.showBlockInMap();
		this.showLinqSequencesInMap(this.dynamicblock.Type);
	}

		/**
	 * Show or hide the blockmarkers
	 * @param $event The click event
	 */
	toggleBlock($event){
		$event.stopPropagation();

		this.showBlock = this.showBlock ? false : true;
		
		this.clearMap();

		if (this.showBlock) {
			this.showBlockInMap();
		}
	}

	toggleLS($event, type){
		$event.stopPropagation();
		this.showLS = this.showLS ? false : true;
		if(this.showLS){
			this.showLinqSequencesInMap(type);	
		}
		else{
			this.linkSequencesInMap.forEach(ls => {
				ls.removeFrom(this.map);
			});
		}
	}

	toggleEdit(){
		if(this.dynamicblock.Type != this.dynamicBlockType.NVDB){
			this.expandededit = !this.expandededit;
		}
	}

	showBlockInMap() {
		//COORD
		if (this.dynamicblock.Type == this.dynamicBlockType.COORD) {
			const startIcon = L.icon({
				iconUrl: './assets/images/svg/icon-map-db-' + this.color.replace("#", "") + '.svg',
				iconSize: [26, 38],
				iconAnchor: [13, 38]
			});

			const startLatlng = new L.LatLng(parseFloat(this.coord.X), parseFloat(this.coord.Y));
			this.marker = L.marker(startLatlng, { icon: startIcon, draggable: false }).addTo(this.map);
			this.radiusOnMarker = L.circle(startLatlng,{radius:this.coord.Radius,  color:this.color}).addTo(this.map);
		}
		//Polygon
		if(this.dynamicblock.Type == this.dynamicBlockType.Polygon){
			const latlngs = this.getPolygonLatLngArray(this.polygon.WKT);
			this.polygonInMap = L.polygon(latlngs,{color:this.color}).addTo(this.map);
		}
	}

	showLinqSequencesInMap(type): void {
		if(this.linkSequencesInMap){
			this.linkSequencesInMap.forEach(ls => {
				ls.addTo(this.map);
			});
		}
		else {
			this.showLinqSequencesAffectedByType(type);
		}
	}

	showLinqSequencesAffectedByType = async (type) => {
		let linkSequences: string[] = null;
		if(type == DynamicBlockTypes.Coord){
			const ll = L.latLng(parseFloat(this.coord.X), parseFloat(this.coord.Y));
			const latlng = L.point((this.applicationRepo.mapCrs as any).project(ll));
		
			const point = latlng.x + "," +  latlng.y;
			linkSequences = await this.dynamicblockService.getLinkSequencesGeometryByCoord(this.coord.Radius, point);	
		}

		if(type == DynamicBlockTypes.Polygon){

			const bounds = this.polygonInMap.getBounds();
			console.log(this.polygonInMap);
			console.log(bounds.toBBoxString());
			
			const northEast = L.point((this.applicationRepo.mapCrs as any).project(bounds.getNorthEast() as any));
			const southWest = L.point((this.applicationRepo.mapCrs as any).project(bounds.getSouthWest() as any));

			const projectedBounds = southWest.x + "," + southWest.y + "," + northEast.x + "," + northEast.y;
			linkSequences = await this.dynamicblockService.getLinkSequencesGeometryByPolygon(projectedBounds);
		}
		
		if(linkSequences != null || linkSequences != undefined){
			this.linkSequencesInMap = [];
			linkSequences.forEach(ls => {
				const latlngs = this.getPolylineLatLngArray(ls);
				
				const outPolyline = L.polyline(latlngs, {color:"#fff", weight:6}).addTo(this.map);
				const polyline = L.polyline(latlngs, {color:this.color}).addTo(this.map);
				this.linkSequencesInMap.push(outPolyline);
				this.linkSequencesInMap.push(polyline);
				
			});
		}
	}

	getPolygonLatLngArray(wkt:string):any[]{
		
		const parse = wktToGeoJSON(wkt);
		const latlngs = ((parse as GeoJSON.Polygon).coordinates);
		const lngLats: L.LatLng[] = [];

		latlngs[0].forEach(ll => {
			const latlng = new L.LatLng(ll[1], ll[0]);
			lngLats.push(latlng);
		});

		return lngLats;
	}
	
	getPolylineLatLngArray(wkt:string):L.LatLng[]{
		
		const parse = wktToGeoJSON(wkt);

		const latlngs = ((parse as GeoJSON.LineString).coordinates);
		const lngLats: L.LatLng[] = [];

		latlngs.forEach(ll => {
			const latlng = this.mapCrs.unproject({ x: ll[0], y: ll[1] });
			lngLats.push(latlng);
		});

		return lngLats;
	}

	clearMap() {
		if (!this.map) {
			return;
		}

		if (this.marker) {
			this.marker.removeFrom(this.map);
			this.marker = null;
		}

		if(this.radiusOnMarker){
			this.radiusOnMarker.removeFrom(this.map);
			this.radiusOnMarker = null;
		}

		if(this.polygonInMap){
			this.polygonInMap.removeFrom(this.map);
			this.polygonInMap = null;
		}
		if(this.linkSequencesInMap){
			this.linkSequencesInMap.forEach(ls => {
				ls.removeFrom(this.map);
			})
			this.linkSequencesInMap = null;
		}
	}

	/**
	 * Zoom to the extent of the block
	 * @param $event The click event
	 */
	zoomToBlock($event) {
		$event.stopPropagation();

		//COORD
		if(this.dynamicblock.Type == this.dynamicBlockType.COORD){
			this.map.fitBounds(this.marker.getLatLng().toBounds(200));
		}
		//Polygon
		if(this.dynamicblock.Type == this.dynamicBlockType.Polygon){
			this.map.fitBounds(this.polygonInMap.getBounds(), {padding:[20,20]});
		}
		
		
	}

	removeBlock($event) {
		$event.stopPropagation();
		//COORD

		if (this.dynamicblock.Type == this.dynamicBlockType.COORD) {
			this.parent.removeRow(this.coord, null);
		}
		//Polygon
		if (this.dynamicblock.Type == this.dynamicBlockType.Polygon) {
			this.parent.removeRow(null, this.polygon);
		}


	}


}
