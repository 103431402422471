import { autoinject, bindable, bindingMode, computedFrom, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { DynamicBlockState, DynamicBlockTypes } from "components/admin/admin-constants/index";
import moment from "moment";
import { Subscription, EventAggregator } from "aurelia-event-aggregator";
import { AlertModel, Alert } from "components/common/dialog/alert";
import { DialogService } from "aurelia-dialog";
import * as L from 'leaflet'
import { wktToGeoJSON } from "@terraformer/wkt"
import { DynamicBlocks } from "components/admin/nav-bar-tabs/dynamic-blocks/dynamic-blocks";
import { Coord, CoordDynamicblock, DynamicBlocksService, IDynamicBlock, Polygon, PolygonDynamicBlock } from 'components/admin/services/dynamic-block-service';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { AppAuthService } from 'services/authentication/app-auth-service';
import { AppRole } from 'services/authentication/models/app-roles.enum';
import { IPrincipal } from 'services/authentication/models/principal.interface';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { IMapRoutePoint } from 'services/application-repository/models/map-route-point.interface';
import { AdminDynamicBlockEventItemType } from 'components/admin/admin-constants/event-types/admin-dynamic-block-event-item-type.enum';
import { IconService } from 'services/assets/services/icon.service';
import { IconType } from 'services/assets/enums/icon-type.enum';



@autoinject()
export class DynamicblockSettings {
	@bindable({ defaultBindingMode: bindingMode.twoWay }) dynamicblock: IDynamicBlock;

	thisVM = this;
	expandedsettings = true;
	expandedcreate = true;

	coord: Coord = new Coord;
	polygon: Polygon = new Polygon;

	saveEnabled = false;
	checkedInEnable = false;

	dynamicblockColorCount = 0;
	private blockColors: string[] = ['#1f6575', '#a0316d', '#3831a0', '#7ba031'];
	private mapLocationSelectedSubscription: Subscription;
	private principal: IPrincipal;
	private drawnItems = new L.LayerGroup();
	private clickedMarker: L.Marker;
	

    dynamicBlockType = {
        NVDB: DynamicBlockTypes.NVDB,
		COORD: DynamicBlockTypes.Coord,
		POLYGON: DynamicBlockTypes.Polygon
    }

	dynamicblockTypes = [
		{
			id: DynamicBlockTypes.NVDB,
			name: 'NVDB'
		}, {
			id: DynamicBlockTypes.Polygon,
			name: 'Polygon'
		}, {
			id: DynamicBlockTypes.Coord,
			name: 'Coord'
		}
	]

	private logger: Logger;

	constructor(private applicationRepo: ApplicationRepository,
		private eventAggregator: EventAggregator,
		private appAuthService: AppAuthService,
		private dialogService: DialogService,
		private dynamicblockService: DynamicBlocksService,
		private dynamicBlockList: DynamicBlocks,
		private iconService: IconService) {
		this.logger = LogManager.getLogger('DynamicblockSettings'); 
	}

	attached(){
        if(this.dynamicblock.Type == this.dynamicBlockType.COORD){
			this.applicationRepo.canAddDynamicBlocks = this.canAddDynamicBlocks;
			this.updateSaveEnable((this.dynamicblock as CoordDynamicblock).Coordblocks.length)
        }
        if(this.dynamicblock.Type == this.dynamicBlockType.POLYGON){
			this.applicationRepo.canAddDynamicBlocksPolygon = this.canAddDynamicBlocksPolygon;
			this.updateSaveEnable((this.dynamicblock as PolygonDynamicBlock).Polygonblocks.length);
		}

		if(!this.dynamicblock.CheckedIn && this.dynamicblock.Id != null){
			this.checkedInEnable = true;
		}
		if(this.dynamicblock.CheckedIn && this.dynamicblock.Id){
			this.checkedInEnable = false;
		}
		
        
        this.mapLocationSelectedSubscription = this.eventAggregator.subscribe(MapEventType.MAP_LOCATION_SELECTED, (locationEvent) => {
            if (locationEvent.locationType === 'COORDBLOCK') {
                this.blockLocationSelected(locationEvent);
            }
        });
		this.mapLocationSelectedSubscription = this.eventAggregator.subscribe("POLYGONEVENT", () => {
			this.polygonBlockLocationSelected();
		});
		this.principal = this.appAuthService.getPrincipal();
		
		
		this.applicationRepo.map.on(L.Draw.Event.CREATED, (e: L.LayerEvent) => {
            const layer = e.layer as L.Layer;
			this.drawnItems.clearLayers();
			layer.addTo(this.drawnItems);
			this.layerToWKT(layer);
			this.applicationRepo.map.addLayer(this.drawnItems);
		});
	}
	
	detached(){
		if(this.dynamicblock){
			this.dynamicblock = null;
		}
		if(this.polygon){
			this.polygon = new Polygon;
		}
		if(this.coord){
			this.coord = new Coord;
		}
		if(this.clickedMarker){
			this.clickedMarker.removeFrom(this.applicationRepo.map);
		}
		if(this.drawnItems){
			this.drawnItems.clearLayers();
			this.drawnItems.removeFrom(this.applicationRepo.map);
		}
		this.saveEnabled = false;
	}
    /**
     * From contextmenu
     * @param event 
     */
    polygonBlockLocationSelected(){
		
        const polygon_options = {
            allowIntersection: false, // Restricts shapes to simple polygons
			shapeOptions: {
				color: '#97009c'
			},
		}
		
		const polygonDrawer = new L.Draw.Polygon(this.applicationRepo.map as L.DrawMap, polygon_options);
		
		L.drawLocal.draw.handlers.polygon.tooltip.start = "Klicka för att starta rita en polygon.";
		L.drawLocal.draw.handlers.polygon.tooltip.cont = "Klicka för att fortsätta rita polygonen.";
		L.drawLocal.draw.handlers.polygon.tooltip.end = "Klicka på startpunkten för att stänga polygonen.";
		
		polygonDrawer.enable();
	}

	/**
	 * From contextmenu
	 * @param event 
	 */
	blockLocationSelected(event: any): void {
		let searchItem: IMapRoutePoint;
		if (event.detail) {
			searchItem = event.detail as IMapRoutePoint;
		}
		else {
			searchItem = event as IMapRoutePoint;
		}

		if (searchItem.name != null) {
			this.coord.X = searchItem.latlng.lat.toString();
			this.coord.Y = searchItem.latlng.lng.toString();
			if(this.clickedMarker){
				this.clickedMarker.removeFrom(this.applicationRepo.map);
			}
			const startIcon = L.icon({
				iconUrl: this.iconService.getIcon(IconType.IconMapDbDbTempMarker),
				iconSize: [26, 38],
				iconAnchor: [13, 38]
			});
			const latlng = new L.LatLng(parseFloat(this.coord.X), parseFloat(this.coord.Y));
			this.clickedMarker = L.marker(latlng, { icon: startIcon, draggable: false }).addTo(this.applicationRepo.map);
		}
	}

	/**
	 * replaces 'canAddDynamicBlocks' in appRepo with this implementation
	 */
	canAddDynamicBlocks = (): boolean => {
		return this.dynamicblock != null && this.dynamicblock.Type == DynamicBlockTypes.Coord;
	}

	canAddDynamicBlocksPolygon = (): boolean =>{
		return this.dynamicblock != null && this.dynamicblock.Type == DynamicBlockTypes.Polygon;
	}

	@computedFrom('principal')
	get isCommitter(): boolean {
		if (!this.principal) {
			return false;
		}
		return this.principal.isInRole(AppRole.VV_COMMITTER)
	}

	@computedFrom('dynamicBlock.Type')
    get dynamicBlockTypeName(): string {
        if (!this.dynamicblock || this.dynamicblock.Type == undefined || this.dynamicblock.Type == null) {
            return '';
        }

        return this.dynamicblockTypes.find(x => x.id === this.dynamicblock.Type).name;
	}
	
	toggleSettings() {
        this.expandedsettings = !this.expandedsettings;
		this.logger.debug('Expanded: ', this.expandedsettings);
	}

	toggleCreate(){
		this.expandedcreate = !this.expandedcreate;
		this.logger.debug('Expanded: ', this.expandedcreate);
	}

	fromDateChanged(event: CustomEvent){
		if (!event.detail) {
			this.dynamicblock.ValidFrom = null;
			return;
		}
		const date = (event.detail as Date);
		this.dynamicblock.ValidFrom = +moment(date).format('YYYYMMDD');
	}

	toDateChanged(event: CustomEvent){
		if (!event.detail) {
			this.dynamicblock.ValidTo = null;
			return;
		}
		const date = (event.detail as Date);
		this.dynamicblock.ValidTo = +moment(date).format('YYYYMMDD');
	}

	commentChanged(event){
		if(this.dynamicblock.Type == DynamicBlockTypes.Coord){
			this.updateSaveEnable((this.dynamicblock as CoordDynamicblock).Coordblocks.length);
		}

		if(this.dynamicblock.Type == DynamicBlockTypes.Polygon){
			this.updateSaveEnable((this.dynamicblock as PolygonDynamicBlock).Polygonblocks.length);
		}
	}

	addRow(){
	
		//COORD
		if (this.dynamicblock.Type == DynamicBlockTypes.Coord) {
			(this.dynamicblock as CoordDynamicblock).Coordblocks.push(this.coord);
			
			this.coord = new Coord;

			this.clickedMarker.removeFrom(this.applicationRepo.map);
			this.updateSaveEnable((this.dynamicblock as CoordDynamicblock).Coordblocks.length);
		}
		//POLYGON
		if(this.dynamicblock.Type == DynamicBlockTypes.Polygon){
			(this.dynamicblock as PolygonDynamicBlock).Polygonblocks.push(this.polygon);
			
			this.polygon = new Polygon;
			
			this.drawnItems.clearLayers();
			this.updateSaveEnable((this.dynamicblock as PolygonDynamicBlock).Polygonblocks.length);
		}
	}

	private updateSaveEnable(length: number){
		if (this.dynamicblock.Comment != null && length != 0) {
			this.saveEnabled = true;
		}
	}

	removeRow(coord?:Coord, polygon?:Polygon){

		//COORD
		if(this.dynamicblock.Type == this.dynamicBlockType.COORD){
			(this.dynamicblock as CoordDynamicblock).Coordblocks = (this.dynamicblock as CoordDynamicblock).Coordblocks.filter(c => c != coord);
			this.coord = new Coord;
			if((this.dynamicblock as CoordDynamicblock).Coordblocks.length == 0){
				(this.dynamicblock as CoordDynamicblock).Coordblocks = [];
				this.saveEnabled = false;
			}
		}
		//Polygon
		if(this.dynamicblock.Type == this.dynamicBlockType.POLYGON){
			(this.dynamicblock as PolygonDynamicBlock).Polygonblocks = (this.dynamicblock as PolygonDynamicBlock).Polygonblocks.filter(p => p != polygon);
			this.polygon = new Polygon;
			if((this.dynamicblock as PolygonDynamicBlock).Polygonblocks.length == 0){
				(this.dynamicblock as PolygonDynamicBlock).Polygonblocks = [];
				this.saveEnabled = false;
			}
		}
	}

	getNextColor(): string {
		if (this.dynamicblock.Type == DynamicBlockTypes.Coord) {
			if ((this.dynamicblock as CoordDynamicblock).Coordblocks.length === 0) {
				this.dynamicblockColorCount = 0;
			}
		}
		if (this.dynamicblock.Type == DynamicBlockTypes.Polygon) {
			if ((this.dynamicblock as PolygonDynamicBlock).Polygonblocks.length === 0) {
				this.dynamicblockColorCount = 0;
			}
		}
		const ret = this.blockColors[this.dynamicblockColorCount];

		if (this.dynamicblockColorCount === 3) {
			this.dynamicblockColorCount = 0;
		}
		else {
			this.dynamicblockColorCount++;
		}

		return ret;
	}

	deleteBlockDialog(){
		if (!this.isCommitter) {
			const notAuthModel: AlertModel = {
				Mode: "error",
				ErrorMessage: "Du saknar behörighet för att ta bort dynamicblock"
				
			};

			this.dialogService.open({viewModel: Alert, model: notAuthModel});
			return
		}

		const alertModel: AlertModel = {
			Mode: "question",
			ErrorMessage: "Vill du ta bort dynamicblock?",
			ExtraInfo: "Dynamicblock tas bort ur produktion, operationen kan inte ångras.",
			AbortText: "Avbryt",
			ConfirmText: "Ta bort",
			ConfirmButtonColor: "red"
		};

		this.dialogService.open({viewModel: Alert, model: alertModel}).whenClosed(response => {
			if (!response.wasCancelled) {
				this.deleteDynamicBlock();
			}
		});
	}

	saveBlockDialog() {
		// User needs to be in the role VV_COMMITTER in order to save in a dynamicblock
		if (!this.isCommitter) {
			const notAuthModel: AlertModel = {
				Mode: "error",
				ErrorMessage: "Du saknar behörighet för att spara dynamicblock"
			};

			this.dialogService.open({viewModel: Alert, model: notAuthModel});
			return
		}

		const alertModel: AlertModel = {
			Mode: "question",
			ErrorMessage: "Vill du spara denna Dynamicblock?",
			AbortText: "Avbryt",
			ConfirmText: "Spara"
		};

		this.dialogService.open({viewModel: Alert, model: alertModel}).whenClosed(response => {
			if (!response.wasCancelled) {
				this.eventAggregator.publish(AdminDynamicBlockEventItemType.SET_DYNAMICBLOCK_LOADING);
				if(this.dynamicblock.Id != null){
					this.updateDynamicBlock();
				}
				else{
					this.saveDynamicBlock();
				}
			}
		});
	}
	

	checkInBlockDialog(){
		// User needs to be in the role VV_COMMITTER in order to check in a dynamicblock
		if (!this.isCommitter) {
			const notAuthModel: AlertModel = {
				Mode: "error",
				ErrorMessage: "Du saknar behörighet för att checka in dynamicblock"
			};

			this.dialogService.open({viewModel: Alert, model: notAuthModel});
			return
		}

		const alertModel: AlertModel = {
			Mode: "question",
			ErrorMessage: "Vill du checka in denna Dynamicblock?",
			ExtraInfo:"Dynamicblock aktiveras och används i produktion.",
			AbortText: "Avbryt",
			ConfirmText: "Checka in"
		};

		this.dialogService.open({viewModel: Alert, model: alertModel}).whenClosed(response => {
			this.eventAggregator.publish(AdminDynamicBlockEventItemType.SET_DYNAMICBLOCK_LOADING);
			if (!response.wasCancelled) {
				this.checkInDynamicBlock();
			}
		});
	}
	checkInDynamicBlock = async () => {
		await this.dynamicblockService.checkInDynamicBlock(this.dynamicblock.Id).then(x =>{
			this.eventAggregator.publish(AdminDynamicBlockEventItemType.RELOAD);
			this.eventAggregator.publish(AdminDynamicBlockEventItemType.SET_DYNAMICBLOCK, this.dynamicblock);
			this.checkedInEnable = false;
		}).catch(error => console.log(error));
	}

	saveDynamicBlock = async () => {
		await this.dynamicblockService.saveDynamicBlock(this.dynamicblock).then(x => {
			this.checkedInEnable = true;
			this.dynamicblock.Id = x;
			this.eventAggregator.publish(AdminDynamicBlockEventItemType.RELOAD);
			this.eventAggregator.publish(AdminDynamicBlockEventItemType.SET_DYNAMICBLOCK, this.dynamicblock);
		}).catch(error => console.log(error));
	}
	updateDynamicBlock = async () => {
		await this.dynamicblockService.updateDynamicBlock(this.dynamicblock).then(x => {
			this.eventAggregator.publish(AdminDynamicBlockEventItemType.RELOAD);
			this.eventAggregator.publish(AdminDynamicBlockEventItemType.SET_DYNAMICBLOCK, this.dynamicblock);
			this.checkedInEnable = true;
		}).catch(error => console.log(error));
	}
	
	deleteDynamicBlock = async () => {
		await this.dynamicblockService.deleteDynamicBlock(this.dynamicblock.Id).then(x =>{
			this.dynamicblock = null;
			this.eventAggregator.publish(AdminDynamicBlockEventItemType.RELOAD);
		}).catch(error => console.log(error));
	}

	private layerToWKT(drawnItems:L.Layer) :void {
		const layer = drawnItems;
		if(layer instanceof L.Polygon){
			const geojson = layer.toGeoJSON();
			const wkt = wktToGeoJSON(geojson.geometry);
			this.polygon.WKT = wkt;
		}
	}
}