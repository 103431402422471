// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dynamicblock-card .block-btn-header {
    display: inline-block;
    padding: 6px 12px;
    text-align: left;
    background-color: #444 !important;
    color: #eee !important;  
    width: 100%;
    z-index: 999;
    box-shadow: none !important;
    border-radius: 0px !important;
    line-height: 1.42857143;
	vertical-align: middle;
	margin-top: 5px;
}

.dynamicblock-card .block-action-btn {
    font-size: 16px;
    padding: 0px 3px 0px 3px;
    margin-left: 0px !important;
}

.dynamicblock-card .block-info {
    width: 66%;
    float: left;
	position: relative;
	color: #eee;
}
.dynamicblock-card .row-info{
	min-width: 60%;
	display: inline-block;
}
.dynamicblock-card .block-info-actions {
    width: 33%;
    float: right;
    position: relative;
}
.btn:hover, .btn:focus, .btn.focus {
    color: #FFF;
}
`, "",{"version":3,"sources":["webpack://./src/components/admin/routing/dynamicblock-settings/dynamicblock-card/dynamicblock-card.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,gBAAgB;IAChB,iCAAiC;IACjC,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,6BAA6B;IAC7B,uBAAuB;CAC1B,sBAAsB;CACtB,eAAe;AAChB;;AAEA;IACI,eAAe;IACf,wBAAwB;IACxB,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,WAAW;CACd,kBAAkB;CAClB,WAAW;AACZ;AACA;CACC,cAAc;CACd,qBAAqB;AACtB;AACA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,WAAW;AACf","sourcesContent":[".dynamicblock-card .block-btn-header {\r\n    display: inline-block;\r\n    padding: 6px 12px;\r\n    text-align: left;\r\n    background-color: #444 !important;\r\n    color: #eee !important;  \r\n    width: 100%;\r\n    z-index: 999;\r\n    box-shadow: none !important;\r\n    border-radius: 0px !important;\r\n    line-height: 1.42857143;\r\n\tvertical-align: middle;\r\n\tmargin-top: 5px;\r\n}\r\n\r\n.dynamicblock-card .block-action-btn {\r\n    font-size: 16px;\r\n    padding: 0px 3px 0px 3px;\r\n    margin-left: 0px !important;\r\n}\r\n\r\n.dynamicblock-card .block-info {\r\n    width: 66%;\r\n    float: left;\r\n\tposition: relative;\r\n\tcolor: #eee;\r\n}\r\n.dynamicblock-card .row-info{\r\n\tmin-width: 60%;\r\n\tdisplay: inline-block;\r\n}\r\n.dynamicblock-card .block-info-actions {\r\n    width: 33%;\r\n    float: right;\r\n    position: relative;\r\n}\r\n.btn:hover, .btn:focus, .btn.focus {\r\n    color: #FFF;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
